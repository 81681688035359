import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import VueTilt from 'vue-tilt.js';

import Home from '../views/Home.vue';
import Login from '../components/Login.vue';
import LoginAdmin from '../components/LoginAdmin.vue';

Vue.use(VueRouter);
Vue.use(VueTilt);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/admin',
    name: 'admin',
    component: LoginAdmin,
  },
  {
    path: '/odzyskiwanie_hasla',
    name: 'recovery',
    component: () => import('../components/Recovery.vue'),
  },
  {
    path: '/resetowanie_hasla',
    name: 'reset_password',
    component: () => import('../components/ResetPassword.vue'),
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  //transports
  {
    path: '/transport/dodaj',
    name: 'addTransport',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/transport/AddV3.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/transport/wszystkie/:id?',
    name: 'allTransport',
    component: () => import('../views/transport/List.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/transport/dostawy/:id?',
    name: 'DeliveryList',
    component: () => import('../views/transport/DeliveryList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/transport/odbiory/:id?',
    name: 'PickUpList',
    component: () => import('../views/transport/PickUpList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/transport/ustawienia',
    name: 'settingsTransport',
    component: () => import('../views/transport/admin/Config.vue'),
    meta: {
      requiresAuth: true,
      isAdmin: true,
    },
  },
  {
    path: '/transport/:id',
    name: 'singleTransport',
    component: () => import('../views/transport/Single.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  // payments
  {
    path: '/finanse/podsumowanie/:id?',
    name: 'paymentsSummary',
    component: () => import('../views/payments/Summary.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/finanse/sposob-rozliczen',
    name: 'paymentsConfig',
    component: () => import('../views/payments/Config.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/finanse/dodaj-platnosc',
    name: 'paymentsAdd',
    component: () => import('../views/payments/admin/Add.vue'),
    meta: {
      requiresAuth: true,
      isAdmin: true,
    },
  },
  {
    path: '/finanse/dodaj-rozliczenie-za-pobranie',
    name: 'paymentsPickupAdd',
    component: () => import('../views/payments/admin/AddPickup.vue'),
    meta: {
      requiresAuth: true,
      isAdmin: true,
    },
  },
  {
    path: '/finanse/dodaj-fakture',
    name: 'paymentsAddInvoice',
    component: () => import('../views/payments/admin/AddInvoice.vue'),
    meta: {
      requiresAuth: true,
      isAdmin: true,
    },
  },
  {
    path: '/finanse/lista-platnosci',
    name: 'paymentsList',
    component: () => import('../views/payments/admin/List.vue'),
    meta: {
      requiresAuth: true,
      isAdmin: true,
    },
  },
  {
    path: '/finanse/faktury/:id?',
    name: 'allInvoices',
    component: () => import('../views/payments/admin/InvoiceList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/finanse/platnosci/:id?',
    name: 'allPayments',
    component: () => import('../views/payments/admin/PaymentsList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/finanse/platnosc/:id?',
    name: 'paymentsSingle',
    component: () => import('../views/payments/Single.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/finanse/wyplaty-pobran/:id?',
    name: 'allPickupPayments',
    component: () => import('../views/payments/admin/PickupPaymentsList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/finanse/pobranie/:id?',
    name: 'pickupsSingle',
    component: () => import('../views/payments/PickupSingle.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  //tickets
  {
    path: '/zgloszenia/wszystkie',
    name: 'ticketsList',
    component: () => import('../views/tickets/List.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/zgloszenia/:id',
    name: 'ticketsSingle',
    component: () => import('../views/tickets/Single.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  //customers
  {
    path: '/uzytkownicy/wszyscy',
    name: 'allCustomers',
    component: () => import('../views/customers/List.vue'),
    meta: {
      requiresAuth: true,
      isAdmin: true,
    },
  },
  {
    path: '/uzytkownicy/:id?',
    name: 'singleCustomer',
    component: () => import('../views/customers/Single.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: '/uzytkownicy/twoje-konto',
  //   name: 'singleCustomer',
  //   component: () => import( '../views/customers/Single.vue' ),
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  {
    path: '/administatorzy/wszyscy',
    name: 'allAdmins',
    component: () => import('../views/admins/List.vue'),
    meta: {
      requiresAuth: true,
      isAdmin: true,
    },
  },
  {
    path: '/administatorzy/komunikaty',
    name: 'allMessages',
    component: () => import('../views/messages/List.vue'),
    meta: {
      requiresAuth: true,
      isAdmin: true,
    },
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/ustawienia',
    name: 'settings',
    component: () => import('../views/Settings.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/cennik',
    name: 'priceTableCustomer',
    component: () => import('../views/priceTable/SingleV2.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/cennik/:id',
    name: 'priceTable',
    component: () => import('../views/priceTable/SingleV2.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/drivers',
    name: 'allDrivers',
    component: () => import(/* webpackChunkName: drivers" */ '../views/drivers/List.vue'),
    meta: {
      requiresAuth: true,
      isAdmin: true,
    },
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next('/login');
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.isAdmin)) {
    if (store.getters.isAdmin) {
      next();
      return;
    }
    next('/');
  } else {
    next();
  }
});

export default router;
