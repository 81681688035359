<template>
  <v-container fluid class="grey lighten-4">
    <v-row>
      <v-col cols="12" md="6" v-if="statements.length">
        <v-card
          class="mx-auto mb-10"
          color="amber"
          dark
          v-for="(item, index) in statements"
          :key="index"
        >
          <v-card-title>
            <v-icon large left> mdi-access-point </v-icon>
            <span class="title font-weight-light">{{ item.type }}</span>
          </v-card-title>

          <v-card-text class="headline font-weight-bold">
            {{ item.content }}
          </v-card-text>

          <v-card-actions>
            <v-list-item class="grow">
              <v-list-item-avatar color="grey darken-3">
                <span class="white--text">
                  {{ item.name.substr(0, 1) }} {{ item.surname.substr(0, 1) }}
                </span>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ item.name }} {{ item.surname }}</v-list-item-title>
              </v-list-item-content>

              <v-row align="center" justify="end">
                <v-btn dark fab top right color="dark accent-3" @click="dialog = true">
                  <v-icon>mdi-email</v-icon>
                </v-btn>
              </v-row>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card class="mx-auto mb-10" color="grey darken-4" dark>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">Dane klienta</div>
              <v-list-item-title class="headline mb-1"
                >{{ customer.name }} {{ customer.surname }}</v-list-item-title
              >
              <v-list-item-subtitle class="mt-3"
                >nr telefonu: {{ customer.phone }}</v-list-item-subtitle
              >
              <v-list-item-subtitle class="mt-3"
                >adres email: {{ customer.email }}</v-list-item-subtitle
              >
              <v-list-item-subtitle
                class="mt-3"
                v-for="item in customer_meta"
                :key="item.meta_name"
              >
                {{ parseMetaName(item.meta_name) }}:
                {{ parseMetaValue(item.meta_value, item.meta_name) }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar size="80" color="amber">
              <span class="white--text headline">
                {{ customer.name.substr(0, 1) }}{{ customer.surname.substr(0, 1) }}
              </span>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6">
        <v-card class="white mb-10">
          <v-img
            class="white--text align-end"
            height="200px"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            src="@/assets/bg/money2.jpg"
          >
            <v-card-title>Suma zaległych pobrań: {{ collect.toFixed(2) }} zł</v-card-title>
          </v-img>
          <v-card-text style="height: 150px; position: relative">
            <v-card-title class="black--text">Pobrania</v-card-title>
            <v-card-text>
              <span v-if="$store.getters.isAdmin">Pobrania klienta</span>
              <span v-if="!$store.getters.isAdmin">Twoje pobrania</span>
            </v-card-text>
            <v-btn
              absolute
              dark
              fab
              top
              right
              color="amber accent-3"
              @click="show('allPickupPayments')"
            >
              <v-icon> mdi-cash-multiple </v-icon>
            </v-btn>
          </v-card-text>
          <v-card-actions>
            <v-btn color="orange" text @click="show('allPickupPayments')"> Zobacz pobrania </v-btn>
          </v-card-actions>
        </v-card>

        <v-card class="white mt-10">
          <v-img
            class="white--text align-end"
            height="200px"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            src="@/assets/bg/money.jpg"
          >
            <v-card-title>Pozostało do zapłaty: {{ (price + pickup).toFixed(2) }} zł</v-card-title>
          </v-img>
          <v-card-text style="height: 150px; position: relative">
            <v-card-title class="black--text">Płatności</v-card-title>
            <v-card-text>
              <span v-if="$store.getters.isAdmin">Płatności klienta</span>
              <span v-if="!$store.getters.isAdmin">Twoje płatności</span>
            </v-card-text>
            <v-btn absolute dark fab top right color="amber accent-3" @click="show('allPayments')">
              <v-icon> mdi-cash-multiple </v-icon>
            </v-btn>
          </v-card-text>
          <v-card-actions>
            <v-btn color="orange" text @click="show('allInvoices')"> Zobacz faktury </v-btn>

            <v-btn color="orange" text @click="show('allPayments')"> Zobacz płatności </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col md="6">
        <v-card class="white">
          <v-card-text class="grey darken-4 pb-10">
            <canvas ref="stats"></canvas>
          </v-card-text>
          <v-card-text style="height: 150px; position: relative">
            <v-card-title class="black--text">Aktualne transporty</v-card-title>
            <v-card-text> Podsumowanie aktualnych transportów </v-card-text>
            <v-btn
              absolute
              dark
              fab
              top
              right
              color="amber accent-3"
              @click="show('monthTransport')"
            >
              <v-icon>mdi-table</v-icon>
            </v-btn>
          </v-card-text>
          <v-card-actions>
            <v-btn color="orange" text @click="show('monthTransport')">
              Utworzone w tym miesiącu
            </v-btn>
            <v-btn color="orange" text @click="show('allTransport')"> Zobacz wszystkie </v-btn>
          </v-card-actions>
        </v-card>

        <v-card class="white">
          <v-card-text class="grey darken-4 pb-10 mt-10">
            <canvas ref="monthly_stats"></canvas>
          </v-card-text>
          <v-card-text style="height: 150px; position: relative">
            <v-card-title class="black--text"
              >Dostarczone transporty z ostatnich miesięcy</v-card-title
            >
            <v-card-text> Statystyki dostarczonych transportów na okres jednego roku </v-card-text>
            <v-btn absolute dark fab top right color="amber accent-3" @click="show('allTransport')">
              <v-icon>mdi-table</v-icon>
            </v-btn>
          </v-card-text>
          <v-card-actions>
            <v-btn color="orange" text @click="show('allTransport')"> Zobacz wszystkie </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- dialogs -->
    <v-dialog v-model="dialog" max-width="700">
      <v-card>
        <v-system-bar color="dark darken-2" dark>
          <v-spacer></v-spacer>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-system-bar>
        <v-toolbar color="grey darken-4" dark>
          <v-toolbar-title>Wyślij wiadomość</v-toolbar-title>
        </v-toolbar>
        <MessageDialog />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import Chart from 'chart.js';

  import MessageDialog from '../../components/MessageDialog.vue';

  export default {
    components: {
      MessageDialog,
    },
    data: () => ({
      customer: {
        name: '',
        surname: '',
      },
      customer_meta: [],
      charts: {
        transport: null,
        stats: null,
      },
      dataset: {
        // STATUS:
        transport: [0, 0, 0, 0, 0, 0, 0, 0, 0],
        transportPrice: [0, 0, 0, 0, 0, 0, 0, 0, 0],
        transportCollect: [0, 0, 0, 0, 0, 0, 0, 0, 0],
        stats: [],
        statsPrice: [],
        statsCollect: [],
      },
      labels: {
        // STATUS:
        transport: [
          'Wymagana akceptacja',
          'Oczekuje na magazyn',
          'Do odbioru u klienta',
          'Oczekuje na wysyłkę',
          'Termin uzgodniony z odbiorcą',
          'Wysłano',
          'Dostarczono',
          'Oczekuje na pobranie',
        ],
        stats: [],
      },
      price: 0,
      pickup: 0,
      collect: 0,
      user_id: null,
      statements: [],
      dialog: false,
    }),
    methods: {
      parseMetaName: function (meta) {
        let name = '';
        switch (meta) {
          case 'bank_account':
            name = 'Numer konta';
            break;
          case 'inadvance_type':
            name = 'Warunek do wypłaty pieniędzy z pobrań';
            break;
          case 'inadvance_method':
            name = 'Sposób rozliczeń pieniędzy z pobrań';
            break;
          case 'inadvance_limit':
            name = 'Okres rozliczeń';
            break;
        }
        return name;
      },
      parseMetaValue: function (value, meta) {
        let name = value;

        if (meta == 'inadvance_method') {
          switch (parseInt(value)) {
            case 1:
              name = 'Do ręki';
              break;
            case 2:
              name = 'Na konto';
              break;
          }
        }

        if (meta == 'inadvance_type') {
          switch (parseInt(value)) {
            case 1:
              name = 'Czas';
              break;
            case 2:
              name = 'Kwota';
              break;
          }
        }
        return name;
      },
      getCustomer: function () {
        axios({
          url: this.$store.state.url + '/api/customer/get-details',
          method: 'POST',
          data: {
            token: this.$store.state.token,
            id: this.user_id,
            type: 'month',
          },
        }).then(resp => {
          if (resp.status === 200) {
            this.customer = resp.data.customer.user;
            this.customer_meta = resp.data.customer_meta;

            resp.data.customer.transports.forEach(t => {
              this.dataset.transport[t.status]++;
              this.dataset.transportPrice[t.status] +=
                t.priceVat + (t.pickup_price ? t.pickup_price : 0.0);
              this.dataset.transportCollect[t.status] += t.money_to_collect;
            });
            this.addTransportChart();
          }
        });
      },
      getPaymentsAndPickupsSummary: function () {
        axios({
          url: this.$store.state.url + '/api/payment/single-summary',
          method: 'POST',
          data: {
            token: this.$store.state.token,
            id: this.user_id,
          },
        }).then(resp => {
          if (resp.status === 200) {
            this.price = resp.data.price ? resp.data.price : 0;
            this.pickup = resp.data.pickup ? resp.data.pickup : 0;
            this.collect = resp.data.collect ? resp.data.collect : 0;
          }
        });
      },
      getMonthlyStats: function () {
        axios({
          url: this.$store.state.url + '/api/transport/monthly-stats',
          method: 'POST',
          data: {
            token: this.$store.state.token,
            id: this.user_id,
          },
        }).then(resp => {
          if (resp.status === 200) {
            for (let [index, x] of resp.data.stats.entries()) {
              this.dataset.stats[index] = x.amount;
              this.dataset.statsPrice[index] = x.price + (x.pickup ? x.pickup : 0.0);
              this.dataset.statsCollect[index] = x.collect;
              this.labels.stats[index] = this.getMonthName(x.month) + ' ' + x.year;
            }
            this.addStatsChart();
          }
        });
      },
      addTransportChart: function () {
        let that = this;
        let colors = [
          'rgb(244, 67, 54)',
          'rgb(0, 0, 0)',
          'rgb(158, 158, 158)',
          'rgb(0, 150, 136)',
          'rgb(76, 175, 80)',
          'rgb(255, 193, 7)',
          'rgb(33, 150, 243)',
          'rgb(63, 81, 181)',
        ];

        this.charts.transport = new Chart(this.$refs.stats, {
          // The type of chart we want to create
          type: 'bar',

          // The data for our dataset
          data: {
            labels: this.labels.transport,
            datasets: [
              {
                label: 'Transporty użytkownika',
                backgroundColor: colors,
                borderColor: 'rgb(255,255,255)',
                data: this.dataset.transport,
              },
            ],
          },

          // Configuration options go here
          options: {
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    callback: function (value) {
                      if (value % 1 === 0) {
                        return value;
                      }
                    },
                  },
                },
              ],
            },
            tooltips: {
              callbacks: {
                // eslint-disable-next-line no-unused-vars
                label: function (tooltipItem, data) {
                  return [
                    `Transporty: ${that.dataset.transport[tooltipItem.index]}`,
                    `Płatności: ${that.dataset.transportPrice[tooltipItem.index].toFixed(2)} zł`,
                    `Pobrania ${that.dataset.transportCollect[tooltipItem.index].toFixed(2)} zł`,
                  ];
                },
              },
            },
            legend: {
              display: false,
            },
          },
        });
      },
      addStatsChart: function () {
        let that = this;
        //let colors = ['rgb(233, 30, 99)', 'rgb(0, 188, 212)', 'rgb(0, 150, 136)', 'rgb(63, 81, 181)', 'rgb(139, 195, 74)', 'rgb(142, 243, 66)', 'rgb(155, 86, 53)', 'rgb(119, 62, 103)', 'rgb(57, 76, 157)', 'rgb(160, 193, 210)', 'rgb(138, 205, 200)', 'rgb(254, 74, 22)'];
        let colors = [
          'rgb(63, 81, 181)',
          'rgb(3, 169, 244)',
          'rgb(139, 195, 74)',
          'rgb(76, 175, 80)',
          'rgb(205, 220, 57)',
          'rgb(255, 193, 7)',
          'rgb(255, 87, 34)',
          'rgb(244, 67, 54)',
          'rgb(128, 24, 24)',
          'rgb(121, 85, 72)',
          'rgb(198, 206, 206)',
          'rgb(33, 150, 243)',
        ];

        this.charts.stats = new Chart(this.$refs.monthly_stats, {
          // The type of chart we want to create
          type: 'bar',

          // The data for our dataset
          data: {
            labels: this.labels.stats,
            datasets: [
              {
                label: 'Dostarczone transporty w miesiącu',
                backgroundColor: colors,
                borderColor: 'rgb(255,255,255)',
                data: this.dataset.stats,
              },
            ],
          },

          // Configuration options go here
          options: {
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    callback: function (value) {
                      if (value % 1 === 0) {
                        return value;
                      }
                    },
                  },
                },
              ],
            },
            tooltips: {
              callbacks: {
                // eslint-disable-next-line no-unused-vars
                label: function (tooltipItem, data) {
                  return [
                    `Transporty: ${that.dataset.stats[tooltipItem.index]}`,
                    `Płatności: ${that.dataset.statsPrice[tooltipItem.index].toFixed(2)} zł`,
                    `Pobrania ${that.dataset.statsCollect[tooltipItem.index].toFixed(2)} zł`,
                  ];
                },
              },
            },
            legend: {
              display: false,
            },
          },
        });
      },
      getMonthName: function (id) {
        let months = [
          'Styczeń',
          'Luty',
          'Marzec',
          'Kwiecień',
          'Maj',
          'Czerwiec',
          'Lipiec',
          'Sierpień',
          'Wrzesień',
          'Październik',
          'Listopad',
          'Grudzień',
        ];

        return months[id - 1];
      },
      show: function (name) {
        if (
          name == 'allInvoices' ||
          name == 'allPayments' ||
          name == 'allPickupPayments' ||
          name == 'paymentsSummary'
        ) {
          if (this.$route.params && this.$route.params.id) {
            this.$router.push({
              name: name,
              params: {
                id: this.$route.params.id,
              },
            });
          } else {
            this.$router.push({
              name: name,
            });
          }
        } else {
          if (name == 'monthTransport') {
            let date = new Date();
            let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

            let month_from = '' + (firstDay.getMonth() + 1);
            let day_from = '' + firstDay.getDate();
            let year_from = firstDay.getFullYear();

            if (month_from.length < 2) month_from = '0' + month_from;
            if (day_from.length < 2) day_from = '0' + day_from;

            let month_to = '' + (lastDay.getMonth() + 1);
            let day_to = '' + lastDay.getDate();
            let year_to = lastDay.getFullYear();

            if (month_to.length < 2) month_to = '0' + month_to;
            if (day_to.length < 2) day_to = '0' + day_to;

            return this.$router.push({
              name: 'allTransport',
              params: {
                id: encodeURIComponent(this.user_id),
              },
              query: {
                from: [year_from, month_from, day_from].join('-'),
                to: [year_to, month_to, day_to].join('-'),
              },
            });
          } else {
            this.$router.push({
              name: name,
              params: {
                id: encodeURIComponent(this.user_id),
              },
            });
          }
        }
      },
      getStatements: function () {
        axios({
          url: this.$store.state.url + '/api/statements/get-current',
          data: {
            token: this.$store.state.token,
          },
          method: 'POST',
        }).then(resp => {
          this.statements = resp.data.statements;
        });
      },
    },

    mounted() {
      this.user_id =
        !!this.$route.params && !!this.$route.params.id
          ? this.$route.params.id
          : this.$store.state.user.id;
      this.getCustomer();
      this.getStatements();
      this.getPaymentsAndPickupsSummary();
      //this.getPickupsSummary();
      this.getMonthlyStats();
    },
  };
</script>
