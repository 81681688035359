<template>
  <v-container fluid fill-height class="grey darken-3 dasboard" align="center" justify="center">
    <FirstLogin v-if="isFirstLogin && !isAdmin" />
    <CustomerDashboard v-else-if="!isAdmin" />
    <AdminDashboard v-else-if="isAdmin" />
  </v-container>
</template>
<script>
  import store from '../store';
  //import CustomerDashboard from '../components/CustomerDashboard.vue'
  import CustomerDashboard from './customers/Single.vue';
  import AdminDashboard from '../components/AdminDashboard.vue';
  import FirstLogin from '../components/FirstLogin.vue';

  import { mdiPlus, mdiLogout, mdiFormatListChecks, mdiResponsive, mdiTruck } from '@mdi/js';
  // @ is an alias to /src

  export default {
    name: 'home',
    components: {
      CustomerDashboard,
      AdminDashboard,
      FirstLogin,
    },
    data: () => ({
      icons: {
        mdiPlus,
        mdiLogout,
        mdiResponsive,
        mdiFormatListChecks,
        mdiTruck,
      },
      isFirstLogin: store.getters.isFirstLogin,
      isAdmin: store.getters.isAdmin,
    }),
    methods: {
      dp: function () {
        this.$router.push({
          name: 'addTransport',
        });
      },
    },
  };
</script>
<style>
  .btnlink {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .dasboard {
    align-items: flex-start !important;
  }
</style>
