<template>
  <v-row>
    <!-- transport -->
    <v-col cols="12">
      <v-card class="white">
        <v-card-text class="grey darken-4 pb-10">
          <canvas ref="stats"></canvas>
        </v-card-text>
        <v-card-text style="height: 150px; position: relative">
          <v-card-title class="black--text">Transporty</v-card-title>
          <v-card-text> Wszystkich transportów: {{ allTransports }} </v-card-text>
          <v-btn absolute dark fab top right color="amber accent-3" @click="show('allTransport')">
            <v-icon>mdi-table</v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-expansion-panels v-model="panels">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <p class="display-1 text--primary ma-0">Zalegające wysyłki od 7 dni</p>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-data-table
              v-model="selected"
              :single-select="false"
              :show-select="true"
              item-key="transport_id"
              :headers="tableHeaders"
              :items="filteredTransports"
              fixed-header
              class="elevation-1 pb-0"
              :loading="isLoading"
              loading-text="Ładowanie... Proszę czekać"
              ref="my-table"
            >
              <template v-slot:no-data> Brak zalegających wysyłek </template>

              <!-- :height="tableHeight" -->
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>
                    <p class="font-weight-medium ma-auto">Wszystkie transporty</p>
                  </v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer v-show="selected.length"></v-spacer>
                  <v-checkbox
                    v-show="selected.length"
                    v-model="isSelected"
                    :value="true"
                    :readonly="true"
                    :label="`zaznaczone ${selected.length}`"
                    hide-details
                    color="grey darken-1"
                    class="shrink elevation-1 mr-2 mt-0 pe-2"
                    @click="clearSelected()"
                  ></v-checkbox>
                  <v-btn
                    v-show="selected.length"
                    color="primary"
                    dark
                    class="mx-1"
                    @click="editStatus = !editStatus"
                  >
                    Status
                  </v-btn>
                  <v-btn
                    v-show="selected.length"
                    color="primary"
                    dark
                    class="mx-1"
                    @click="editDelivery = !editDelivery"
                  >
                    Dostawa
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-text-field
                    v-model="search"
                    label="kod pocztowy, miejscowość lub klient"
                    single-line
                    hide-details
                    append-icon="mdi-magnify"
                  ></v-text-field>
                </v-toolbar>
              </template>

              <template v-slot:[`item.user_id`]="{ item }">
                <a :href="'/uzytkownicy/' + item.user_id">
                  {{ item.name + ' ' + item.surname }}
                </a>
              </template>

              <template v-slot:[`item.r_city`]="{ item }">
                {{ item.r_postcode + ' ' + item.r_city }}
              </template>

              <template v-slot:[`item.weight`]="{ item }"> {{ item.weight }} kg </template>

              <template v-slot:[`item.priceVat`]="{ item }">
                {{
                  (
                    parseFloat(item.priceVat) +
                    parseFloat(item.pickup_price ? item.pickup_price : 0)
                  ).toFixed(2)
                }}
                zł
              </template>

              <template v-slot:[`item.money_to_collect`]="{ item }">
                {{ item.money_to_collect }} zł
              </template>

              <template v-slot:[`item.status`]="{ item }">
                <!-- <v-chip v-if="isAdmin" @click="editedTransport = item; editStatus = !editStatus" :color="getStatusColor(item.status)" dark>{{ getStatusName(item.status) }}</v-chip> -->
                <v-chip :color="getStatusColor(item.status)" dark>{{
                  getStatusName(item.status)
                }}</v-chip>
              </template>

              <template v-slot:[`item.transport_id`]="{ item }">
                <v-tab :to="'/transport/' + item.transport_id"> Zobacz </v-tab>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>

    <v-col cols="12">
      <FullMap :receivers="receivers"></FullMap>
    </v-col>

    <!-- dialogs -->
    <v-dialog v-model="dialog" max-width="700">
      <v-card>
        <v-system-bar color="dark darken-2" dark>
          <v-spacer></v-spacer>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-system-bar>
        <v-toolbar color="grey darken-4" dark>
          <v-toolbar-title>Wyślij wiadomość</v-toolbar-title>
        </v-toolbar>
      </v-card>
    </v-dialog>

    <v-dialog v-if="isAdmin" v-model="editStatus" :dark="false" :light="true" max-width="600px">
      <v-toolbar color="black" :dark="true">
        <v-spacer />
        <v-btn @click="editStatus = false" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <TransportStatus :transport="selected" @updateStatus="updateStatus($event)"></TransportStatus>
    </v-dialog>

    <v-dialog v-if="isAdmin" v-model="editDelivery" :dark="false" :light="true" max-width="410px">
      <v-card class="mx-auto pb-1" max-width="800">
        <v-row v-if="message" class="ma-0">
          <v-col cols="12" sm="12">
            <v-alert type="error" class="ma-0">
              {{ message }}
            </v-alert>
          </v-col>
        </v-row>
        <v-date-picker
          v-model="deliveryDate"
          year-icon="mdi-calendar-blank"
          color="amber darken-1"
          :first-day-of-week="1"
          locale="pl-PL"
          full-width
        ></v-date-picker>
        <v-row class="mx-4 my-2 pa-auto">
          <v-checkbox
            v-model="mail"
            label="Wyślij maila z powiadomieniem do klienta"
            color="primary"
            hide-details
          ></v-checkbox>
        </v-row>
        <v-row class="mx-4 my-2 pa-auto">
          <v-btn
            color="amber accent-3"
            class="grey--text text--darken-4"
            :x-large="true"
            v-tilt
            @click="
              editDelivery = !editDelivery;
              deliveryDate = null;
              mail = false;
            "
          >
            Anuluj
          </v-btn>
          <v-spacer />
          <v-btn
            color="amber accent-3"
            class="grey--text text--darken-4"
            :x-large="true"
            v-tilt
            :disabled="preventBtn || !deliveryDate"
            @click="changeDate('delivery')"
          >
            Zmień
          </v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import store from '../store';
  import Chart from 'chart.js';
  import axios from 'axios';

  import FullMap from '@/components/FullMap.vue';
  import TransportStatus from './TransportStatus.vue';

  export default {
    name: 'AdminDashboard',
    components: {
      FullMap,
      TransportStatus,
    },
    data: () => ({
      charts: {
        transport: null,
      },

      isAdmin: store.getters.isAdmin,
      dataset: {
        transport: [0, 0, 0, 0, 0, 0, 0, 0, 0],
        price: [0, 0, 0, 0, 0, 0, 0, 0, 0],
        collect: [0, 0, 0, 0, 0, 0, 0, 0, 0],
        vovivodes: [],
      },
      labels: {
        // STATUS:
        transport: [
          'Wymagana akceptacja',
          'Oczekuje na magazyn',
          'Do odbioru u klienta',
          'Oczekuje na wysyłkę',
          'Termin uzgodniony z odbiorcą',
          'Wysłano',
          'Dostarczono',
          'Oczekuje na pobranie',
        ],
        voivodes: [],
      },
      allTransports: 0,
      dialog: false,
      postcodes: [],
      receivers: [],

      panels: 0,
      tableHeaders: [
        {
          text: 'Numer transportu',
          align: 'left',
          value: 't_number',
        },
        {
          text: 'Klient',
          value: 'user_id',
        },
        {
          text: 'Dostarczyć',
          value: 'r_city',
          sortable: false,
        },
        {
          text: 'Transport',
          value: 'content',
          sortable: false,
        },
        {
          text: 'Przewidywana dostawa',
          value: 'date_expected',
        },
        {
          text: 'Kwota brutto',
          value: 'priceVat',
          sortable: false,
        },
        {
          text: 'Kwota pobrania',
          value: 'money_to_collect',
          sortable: false,
        },
        {
          text: 'Status',
          value: 'status',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Szczegóły',
          value: 'transport_id',
          sortable: false,
        },
      ],
      transports: [],
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: ['t_number'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
      },
      isLoading: false,

      listSize: [10, 20, 50, 100, -1],
      search: '',

      selected: [],
      isSelected: true,

      editedTransport: {},
      editStatus: false,

      statuses: 10,

      editDelivery: false,
      deliveryDate: null,
      editPickup: false,
      pickupDate: null,
      mail: false,

      preventBtn: false,
      message: null,
    }),
    computed: {
      tableHeight: function () {
        return window.innerHeight * 0.7;
      },
      filteredTransports: function () {
        let search = this.search.toLowerCase().trim();
        return this.transports.filter(item => {
          return (
            !search ||
            item.t_number.toLowerCase().indexOf(search) !== -1 ||
            item.r_city.toLowerCase().indexOf(search) !== -1 ||
            item.r_postcode.indexOf(search) !== -1 ||
            `${item.name} ${item.surname}`.toLowerCase().indexOf(search) !== -1 ||
            `${item.surname} ${item.name}`.toLowerCase().indexOf(search) !== -1
          );
        });
      },
    },
    methods: {
      clearSelected() {
        this.selected = [];
      },
      updateStatus(status) {
        this.selected.forEach(elem => {
          elem.status = status;
        });
        this.editStatus = false;
      },
      changeDate(type) {
        this.preventBtn = true;

        axios({
          url: this.$store.state.url + '/api/transport/modify/date',
          data: {
            token: this.$store.state.token,
            id: this.selected.map(value => {
              return value.transport_id;
            }),
            date: type == 'delivery' ? new Date(this.deliveryDate) : new Date(this.pickupDate),
            type: type,
            mail: this.mail,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status === 200) {
              // date to string
              let date = type == 'delivery' ? new Date(this.deliveryDate) : new Date(this.pickupDate);
              let year = date.getFullYear();
              let month = date.getMonth() + 1;
              let dt = date.getDate();

              if (dt < 10) dt = '0' + dt;
              if (month < 10) month = '0' + month;

              if (type == 'delivery') {
                this.selected.forEach(elem => {
                  elem.date_expected = dt + '.' + month + '.' + year;
                });
                this.editDelivery = false;
                this.deliveryDate = null;
              } else {
                this.selected.forEach(elem => {
                  elem.pickup_date = dt + '.' + month + '.' + year;
                });
                this.editPickup = false;
                this.pickupDate = null;
              }
              this.preventBtn = false;
              this.message = null;
              this.mail = false;
              //this.clearSelected();
            } else {
              this.message = resp.data.message;
              let that = this;
              setTimeout(() => {
                that.preventBtn = false;
              }, 200);
            }
          })
          .catch(() => {
            let that = this;
            setTimeout(() => {
              that.preventBtn = false;
            }, 200);
          });
      },
      searchTransports: function () {
        this.transports = [];
        this.isLoading = true;

        let date = new Date();
        date.setDate(date.getDate() - 7);

        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let dt = date.getDate();
        let hr = date.getHours();
        let min = date.getMinutes();
        let sec = date.getSeconds();
        if (dt < 10) dt = '0' + dt;
        if (month < 10) month = '0' + month;
        if (hr < 10) hr = '0' + hr;
        if (min < 10) min = '0' + min;
        if (sec < 10) sec = '0' + sec;
        let date_to = year + '-' + month + '-' + dt + ' ' + hr + ':' + min + ':' + sec;

        return axios({
          url: this.$store.state.url + '/api/transport/search',
          data: {
            token: this.$store.state.token,
            sortBy: ['t_number'],
            sortDesc: [true],
            // STATUS:
            status: 3,
            overdue: true,
            date_to: date_to,
            nolimit: true,
          },
          method: 'POST',
        }).then(resp => {
          if (resp.data.status === 200) {
            for (let [, t] of resp.data.transports.entries()) {
              if (t.packages) {
                let pack = JSON.parse(t.packages);
                let sumWeight = 0;
                pack.forEach(element => {
                  sumWeight += parseFloat(element.weight);
                });
                t.weight = sumWeight.toFixed(2);
              }

              if (t.date_expected) {
                let date = new Date(t.date_expected);
                if (date.getDate()) {
                  let year = date.getFullYear();
                  let month = date.getMonth() + 1;
                  let dt = date.getDate();
                  if (dt < 10) dt = '0' + dt;
                  if (month < 10) month = '0' + month;
                  t.date_expected = dt + '.' + month + '.' + year;
                } else {
                  t.date_expected = null;
                }
              }

              if (t.date_created) {
                let date = new Date(t.pickup_date);
                if (date.getDate()) {
                  let year = date.getFullYear();
                  let month = date.getMonth() + 1;
                  let dt = date.getDate();
                  if (dt < 10) dt = '0' + dt;
                  if (month < 10) month = '0' + month;
                  t.pickup_date = dt + '.' + month + '.' + year;
                } else {
                  t.pickup_date = null;
                }
              }

              if (t.date_created) {
                let date = new Date(t.date_created);
                if (date.getDate()) {
                  let year = date.getFullYear();
                  let month = date.getMonth() + 1;
                  let dt = date.getDate();
                  let hr = date.getHours();
                  let min = date.getMinutes();
                  if (dt < 10) dt = '0' + dt;
                  if (month < 10) month = '0' + month;
                  if (hr < 10) hr = '0' + hr;
                  if (min < 10) min = '0' + min;
                  t.date_created = dt + '.' + month + '.' + year + ' ' + hr + ':' + min;
                } else {
                  t.date_created = null;
                }
              }
            }

            this.transports = resp.data.transports;
            this.isLoading = false;
          }
        });
      },
      show: function (name) {
        this.$router.push({
          name: name,
        });
      },
      getTextWidth: function (text, font) {
        // re-use canvas object for better performance
        var canvas =
          this.getTextWidth.canvas || (this.getTextWidth.canvas = document.createElement('canvas'));
        var context = canvas.getContext('2d');
        context.font = font ? font : '12px Arial';
        var metrics = context.measureText(text);
        return metrics.width;
      },
      getStatusColor: function (status) {
        let r;
        // STATUS:
        switch (status) {
          case 0:
            r = 'red';
            break;
          case 1:
            r = 'black';
            break;
          case 2:
            r = 'grey';
            break;
          case 3:
            r = 'teal';
            break;
          case 4:
            r = 'green';
            break;
          case 5:
            r = 'amber';
            break;
          case 6:
            r = 'blue';
            break;
          case 7:
            r = 'indigo';
            break;
          case 8:
            r = 'silver';
            break;
          case 9:
            r = 'red darken-4';
            break;
        }
        return r;
      },
      getStatusName: function (status) {
        let r;
        // STATUS:
        switch (status) {
          case 0:
            r = 'Wymagana akceptacja';
            break;
          case 1:
            r = 'Oczekuje na magazyn';
            break;
          case 2:
            r = 'Do odbioru u klienta';
            break;
          case 3:
            r = 'Oczekuje na wysyłkę';
            break;
          case 4:
            r = 'Termin uzgodniony z odbiorcą';
            break;
          case 5:
            r = 'Wysłano';
            break;
          case 6:
            r = 'Dostarczono';
            break;
          case 7:
            r = 'Oczekuje na pobranie';
            break;
          case 8:
            r = 'Zarchiwizowano';
            break;
          case 9:
            r = 'Anulowano';
            break;
        }
        return r;
      },
      addTransportChart: function () {
        let that = this;
        let colors = [
          'rgb(244, 67, 54)',
          'rgb(0, 0, 0)',
          'rgb(158, 158, 158)',
          'rgb(0, 150, 136)',
          'rgb(76, 175, 80)',
          'rgb(255, 193, 7)',
          'rgb(33, 150, 243)',
          'rgb(63, 81, 181)',
        ];

        this.charts.transport = new Chart(this.$refs.stats, {
          // The type of chart we want to create
          type: 'bar',

          // The data for our dataset
          data: {
            labels: this.labels.transport,
            datasets: [
              {
                label: `Transporty (razem ${this.allTransports} transportów)`,
                backgroundColor: colors,
                borderColor: 'rgb(255,255,255)',
                data: this.dataset.transport,
              },
            ],
          },

          // Configuration options go here
          options: {
            scales: {
              xAxes: [
                {
                  gridLines: {
                    offsetGridLines: true,
                  },
                  ticks: {
                    autoSkip: false,
                    maxRotation: 0,
                    mminRotation: 0,
                    //padding: 20,
                    callback: function (value) {
                      if (this.chart.getDatasetMeta(0).data[0]._model) {
                        let expectedTextSize = that.getTextWidth(value);
                        let barWidth = this.chart.getDatasetMeta(0).data[0]._model.width;
                        let offset = that.getTextWidth('...') + 4;

                        if (expectedTextSize + offset > barWidth) {
                          return (
                            value.substr(
                              0,
                              Math.round(value.length / ((expectedTextSize + offset) / barWidth))
                            ) + '...'
                          ); // truncate
                        } else {
                          return value;
                        }
                      } else {
                        return value;
                      }
                    },
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    callback: function (value) {
                      if (value % 1 === 0) {
                        return value;
                      }
                    },
                  },
                },
              ],
            },
            tooltips: {
              callbacks: {
                // eslint-disable-next-line no-unused-vars
                label: function (tooltipItem, data) {
                  return [
                    `Transporty: ${that.dataset.transport[tooltipItem.index]}`,
                    `Płatności: ${that.dataset.price[tooltipItem.index].toFixed(2)} zł`,
                    `Pobrania ${that.dataset.collect[tooltipItem.index].toFixed(2)} zł`,
                  ];
                },
              },
            },
            legend: {
              display: false,
            },
            // onClick: function(e, items){
            //   if ( items.length == 0 ) return; //Clicked outside any bar.

            //   //clickOnChart(lastHoveredIndex); do sth what u want
            // }
          },
        });
        this.charts.transport.update();
      },
      getTransports: function () {
        axios({
          url: this.$store.state.url + '/api/transport/get', // skip 'Zarchiwizowano', 'Anulowano', select specyfic columns
          data: {
            token: this.$store.state.token,
            type: 'transports-receivers',
          },
          method: 'POST',
        }).then(resp => {
          let tmp = [];
          let counter = 0;
          resp.data.transports.forEach(t => {
            this.dataset.transport[t.status]++;
            this.dataset.price[t.status] += t.priceVat + (t.pickup_price ? t.pickup_price : 0.0);
            this.dataset.collect[t.status] += t.money_to_collect;
            counter++;
            // STATUS:
            if (t.status < 6 && t.lat && t.lng) {
              // all undelivered packages
              let d = new Date(t.date_expected);
              let month = '' + (d.getMonth() + 1);
              let day = '' + d.getDate();
              let year = d.getFullYear();
              if (month.length < 2) month = '0' + month;
              if (day.length < 2) day = '0' + day;

              tmp.push({
                lat: t.lat,
                lng: t.lng,
                t_number: t.t_number,
                name: t.name,
                phone: t.phone,
                date_expected: [year, month, day].join('-'),
                transport_id: t.transport_id,
              });
            }
          });

          this.allTransports = counter;
          this.addTransportChart();

          let that = this;
          setTimeout(function () {
            that.receivers = tmp;
          }, 2000);
        });
      },
    },
    created() {
      this.getTransports();
      this.searchTransports();
    },
  };
</script>

<style>
  .v-card,
  .v-card:not(.v-sheet--tile):not(.v-card--shaped),
  .v-sheet {
    border-radius: 0 !important;
  }
</style>
