<template>
  <div>
    <v-progress-circular v-show="loading" indeterminate size="64"></v-progress-circular>
    <v-card v-show="!loading" class="mx-auto" max-width="800" outlined>
      <v-card-text class="py-0">
        <v-row v-if="message">
          <v-col cols="12" sm="12">
            <v-alert type="error" class="ma-0">
              {{ message }}
            </v-alert>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex" cols="12" sm="6">
            <v-radio-group v-model="getStatus" v-if="cancelAvaliable">
              <v-radio
                v-for="item in [].concat(status, statusCancel)"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></v-radio>
            </v-radio-group>
            <v-radio-group v-model="getStatus" v-else>
              <v-radio
                v-for="item in status"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="12" sm="12" class="py-0">
            <v-checkbox
              v-model="infoCheckbox"
              label="Dodaj informacje o powodzie zmiany statusu"
              color="primary"
              hide-details
            ></v-checkbox>

            <v-textarea
              v-show="infoCheckbox"
              v-model="info"
              name="info"
              label="Powód zmiany statusu"
              maxlength="255"
              counter="255"
              auto-grow
            />
          </v-col>

          <v-col cols="12" sm="12" class="py-0">
            <v-checkbox
              v-model="sendNotification"
              label="Wyślij powiadomienie w aplikacji (dzwonek)"
              color="primary"
              hide-details
            ></v-checkbox>
          </v-col>

          <v-col cols="12" sm="12" class="py-0">
            <v-checkbox
              v-model="mail"
              label="Wyślij maila z powiadomieniem do klienta"
              color="primary"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-show="forceCheckbox"
              v-model="force"
              label="Wymuś akcje (spowoduje to oznaczenie wszystkich paczek jako odebrane)"
              color="primary"
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row justify="center" no-gutters class="mt-6 mb-4">
          <v-col cols="12" md="3" align="center">
            <v-btn
              @click="updateStatus()"
              :disabled="preventBtn || getStatus === null"
              color="amber accent-3"
              class="grey--text text--darken-4"
              :x-large="true"
              v-tilt
            >
              Zmień status
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="confirm" max-width="510px">
      <v-card>
        <v-card-title class="justify-center">
          <span> UWAGA </span>
        </v-card-title>
        <v-divider dark class="my-1" />
        <v-card-text>
          <div class="text--primary">
            Anulowanie zlecenia spowoduje, że zostanie ono
            <b>pernamentnie usunięte</b> z systemu. Nie będzie go można w żaden sposób przywrócić.
            <br /><br />
            <h3>Czy rozumiesz konsekwencje?</h3>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="confirm = false"> Anuluj </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="
              updateStatus();
              confirm = false;
            "
          >
            Rozumiem
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'TransportStatus',
    props: {
      transport: Array,
    },
    data: () => ({
      loading: true,
      // STATUS:
      status: [
        {
          id: 0,
          name: 'Wymagana akceptacja',
        },
        {
          id: 1,
          name: 'Oczekuje na magazyn',
        },
        {
          id: 2,
          name: 'Do odbioru u klienta',
        },
        {
          id: 3,
          name: 'Oczekuje na wysyłkę',
        },
        // {
        //   id: 4,
        //   name: "Termin uzgodniony z odbiorcą",
        // },
        {
          id: 5,
          name: 'Wysłano',
        },
        {
          id: 6,
          name: 'Dostarczono',
        },
        {
          id: 7,
          name: 'Oczekuje na pobranie',
        },
        {
          id: 8,
          name: 'Zarchiwizowano',
        },
      ],
      statusCancel: [
        {
          id: 9,
          name: 'Anulowano',
        },
      ],
      getStatus: null,
      message: null,
      confirm: false,
      preventBtn: false,

      cancelAvaliable: true,

      force: false,
      forceCheckbox: false,

      mail: true,
      sendNotification: false,

      infoCheckbox: false,
      info: '',
    }),
    computed: {
      item_length: function () {
        return this.transport.length;
      },
    },
    watch: {
      item_length(v) {
        // STATUS:
        if (v > 1) {
          this.cancelAvaliable = false;
        } else if (v == 1 && this.transport[0].status <= 2) {
          this.cancelAvaliable = true;
        } else {
          this.cancelAvaliable = false;
        }
      },
    },
    methods: {
      updateStatus: function () {
        this.preventBtn = true;
        this.message = null;

        // STATUS:
        if (this.getStatus == 9 && !this.confirm) {
          let that = this;
          setTimeout(() => {
            that.preventBtn = false;
          }, 200);
          return (this.confirm = true);
        }

        axios({
          url: this.$store.state.url + '/api/transport/modify/status',
          data: {
            token: this.$store.state.token,
            id: this.transport.map(value => {
              return value.transport_id;
            }),
            status: this.getStatus,
            force: this.force,
            mail: this.mail,
            notification: this.sendNotification,
            message: this.infoCheckbox ? this.info : '',
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status === 200) {
              this.$emit('updateStatus', this.getStatus);
              this.forceCheckbox = false;
              this.force = false;
              this.getStatus = null;
              this.mail = true;
              this.sendNotification = false;
              this.preventBtn = false;
              return;
            } else if (resp.data.status === 403) {
              this.message = resp.data.message;
              this.forceCheckbox = true;
              let that = this;
              setTimeout(() => {
                that.preventBtn = false;
              }, 200);
              document.getElementsByClassName('v-dialog v-dialog--active')[0].scrollTop = 0;
              return;
            } else {
              this.message = resp.data.message;
              let that = this;
              setTimeout(() => {
                that.preventBtn = false;
              }, 200);
              document.getElementsByClassName('v-dialog v-dialog--active')[0].scrollTop = 0;
              return;
            }
          })
          .catch(() => {
            let that = this;
            setTimeout(() => {
              that.preventBtn = false;
            }, 200);
          });
      },
    },
    mounted() {},
    created() {
      let that = this;
      setTimeout(() => {
        that.loading = false;
      }, 200);
    },
  };
</script>
