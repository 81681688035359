<template>
  <v-card class="white">
    <v-card-text>
      <p class="display-1 text--primary">Docelowe lokalizacje transportów</p>
      <p>
        <router-link :to="{ name: 'DeliveryList' }"
          >{{ counter }} niedostarczonych transportów</router-link
        >
      </p>
      <div>
        <div id="map" class="map"></div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  //import axios from 'axios'
  import tt from '@tomtom-international/web-sdk-maps';

  export default {
    props: {
      receivers: Array,
    },
    name: 'FullMap',
    data: () => ({
      map: null,
      markers: [],
      counter: 0,
      //    receivers: []
    }),
    watch: {
      receivers: function () {
        this.counter = this.receivers.length;
        this.createMarkers();
      },
    },
    methods: {
      getMapStyle: function () {
        let d = new Date();
        let s = '';
        if (d.getHours() < 7 || d.getHours() > 20) {
          s = 'tomtom://vector/1/basic-night';
        } else {
          s = 'tomtom://vector/1/basic-main';
        }
        return s;
      },
      createMarkers: function () {
        let options = {
          closeButton: true,
          offset: {
            top: [0, 0],
            bottom: [0, -40],
            'bottom-right': [0, -70],
            'bottom-left': [0, -70],
            left: [25, -35],
            right: [-25, -35],
          },
        };

        let tmp = [];
        this.receivers.forEach(receiver => {
          let path = this.$router.resolve({
            name: 'singleTransport',
            params: { id: receiver.transport_id },
          }).href;
          const p = new tt.Popup(options).setHTML(
            `<br/>Dostawa na: ${receiver.date_expected}<br/>Transport: <a href="${path}">${receiver.t_number}</a><br/>Adresat: ${receiver.name}<br/>Tel: ${receiver.phone}`
          );

          let m = new tt.Marker().setLngLat([receiver.lng, receiver.lat]).setPopup(p).addTo(this.map);
          tmp.push(m);
        });
        this.markers = tmp;
      },
    },
    mounted() {
      this.map = tt.map({
        key: this.$store.state.maps.key,
        language: this.$store.state.maps.lang,
        container: 'map',
        center: [19.252522, 52.065162],
        style: this.getMapStyle(),
        zoom: 5,
      });

      if (this.receivers.length) {
        this.counter = this.receivers.length;
        this.createMarkers();
      }
    },
  };
</script>

<style>
  @import url('https://api.tomtom.com/maps-sdk-for-web/cdn/5.x/5.49.1/maps/maps.css');
  .map {
    width: 100%;
    height: 500px;
    position: relative;
    overflow: hidden;
  }
  .mapboxgl-marker {
    position: absolute;
    z-index: 1;
  }
  .row {
    position: relative;
    z-index: 2;
  }
  .mapboxgl-popup-content {
    background-color: whitesmoke;
    padding-top: 4px;
    padding-right: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
    border: 2px solid yellowgreen;
  }
  .mapboxgl-popup-close-button {
    right: 0px;
    color: red;
    font-size: 24px;
  }

  .mapboxgl-popup {
    position: absolute;
    z-index: 3;
  }
</style>
