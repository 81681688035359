<template>
  <v-menu
    v-model="isMenuActive"
    offset-y
    :close-on-content-click="false"
    eager
    @hook:updated="updateMenuHeight"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon dark plain retain-focus-on-click v-bind="attrs" v-on="on">
        <v-badge :content="counter" :dot="hideBadgeContent" overlap bordered>
          <v-icon> mdi-bell </v-icon>
        </v-badge>
      </v-btn>
    </template>

    <v-list
      dense
      ref="menuList"
      class="overflow-y-auto mt-auto scroll-container"
      min-width="360"
      max-width="420"
      :max-height="firstChildHeight"
    >
      <v-list-item class="pa-0">
        <v-card class="mx-auto fill-width" outlined>
          <v-card-actions>
            <v-btn outlined rounded text small @click="removeAllNotifications()">
              Oznacz wszystkie jako przeczytane
            </v-btn>

            <v-spacer></v-spacer>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  :color="constHeightBtnColor"
                  v-bind="attrs"
                  v-on="on"
                  @click="setConstHeight()"
                >
                  <v-icon> mdi-ruler </v-icon>
                </v-btn>
              </template>

              <span>Stała długość</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item v-for="(item, index) in items" :key="index" class="pa-0" dense>
        <v-card class="mx-auto fill-width" outlined>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium mb-4">
                <v-row no-gutters>
                  {{ item.title }}
                  <v-spacer></v-spacer>
                  {{ `${index + 1}/${counter}` }}
                </v-row>
              </v-list-item-title>

              <div style="font-size: 14px">
                <pre style="font-family: sans-serif; white-space: pre-wrap">{{ item.message }}</pre>
              </div>
            </v-list-item-content>
          </v-list-item>

          <v-card-actions>
            <v-btn outlined rounded text small @click="removeNotification(index)">
              Oznacz jako przeczytane
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              v-if="item.url"
              outlined
              rounded
              text
              small
              :to="{ path: item.url }"
              @click="isMenuActive = false"
            >
              Idź do
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-list-item>

      <v-list-item class="pa-0" v-show="counter === 0">
        <v-card class="mx-auto fill-width" outlined>
          <v-list-item>
            <v-list-item-content> Brak powiadomień </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'Notifications',
    data: () => ({
      isMenuActive: false,
      constHeight: false,
      firstChildHeight: 224,
      items: [],
      ws: null,
    }),
    computed: {
      counter: {
        get() {
          return this.items.length;
        },
      },
      hideBadgeContent: {
        get() {
          return this.items.length ? false : true;
        },
      },
      constHeightBtnColor: {
        get() {
          return this.constHeight ? 'primary' : '';
        },
      },
    },
    methods: {
      removeAllNotifications() {
        axios({
          url: this.$store.state.url + '/api/notifications/markAsRead',
          data: {
            token: this.$store.state.token,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status === 200) this.items = [];
            if (!this.items.length) this.isMenuActive = false;
          })
          .catch(() => {});
      },
      removeNotification(index) {
        axios({
          url: this.$store.state.url + '/api/notifications/markAsRead',
          data: {
            notification_id: this.items[index].notification_id,
            token: this.$store.state.token,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status === 200) this.items.splice(index, 1);
            if (!this.items.length) this.isMenuActive = false;
          })
          .catch(() => {});
      },
      updateMenuHeight() {
        // two elements height (max 500)
        this.firstChildHeight = this.constHeight
          ? 500
          : Math.min(
              this.$refs?.menuList?.$children[3]?.$el.offsetHeight +
                63 +
                (this.$refs?.menuList?.$children[4]?.$el.offsetHeight || 0) || 224,
              500
            );
      },
      setConstHeight() {
        this.constHeight = !this.constHeight;
        // this.firstChildHeight = 500;
        this.updateMenuHeight();
      },
      getNotifications() {
        axios({
          url: this.$store.state.url + '/api/notifications',
          data: {
            token: this.$store.state.token,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status === 200) {
              this.items = resp.data.notifications;

              const that = this;
              const ws = new WebSocket(
                `${this.$store.state.url
                  .replace(/https:/, 'wss:')
                  .replace(/http:/, 'ws:')}/websockets`,
                ['Bearer', this.$store.state.token]
              );

              ws.onopen = function () {
                // console.log('Websocket opened');
              };
              ws.onmessage = function (event) {
                that.items.push(JSON.parse(event.data));
              };
              ws.onerror = function () {
                // console.log('ERROR');
              };
              ws.onclose = function () {
                setTimeout(() => {
                  // console.log('Websocket reconnecting');
                  that.getNotifications();
                }, 60000);
              };

              this.ws = ws;
            } else {
              const that = this;
              setTimeout(() => {
                that.getNotifications();
              }, 60000);
            }
          })
          .catch(() => {
            const that = this;
            setTimeout(() => {
              that.getNotifications();
            }, 60000);
          });
      },
    },
    mounted() {
      this.getNotifications();
    },
  };
</script>

<style >
  .v-btn--outlined {
    border: thin solid #ffc400 !important;
  }
  .fill-width {
    width: 100%;
  }
</style>
