<template>
  <v-container fluid fill-height class="grey darken-3" @keydown.enter="login">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="black" light flat>
            <v-toolbar-title class="white--text">Zaloguj się</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-alert v-if="message" type="error">
                {{ message }}
              </v-alert>
              <v-row align="center" justify="center">
                <v-col cols="1">
                  <v-icon>{{ icons.mdiAccount }}</v-icon>
                </v-col>
                <v-col>
                  <v-text-field v-model="user.login" label="Login" name="login" type="text" />
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col cols="1">
                  <v-icon>{{ icons.mdiLock }}</v-icon>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="user.pass"
                    id="password"
                    label="Password"
                    name="password"
                    type="password"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-col cols="1" />
            <router-link :to="{ name: 'recovery' }">Zapomniałem hasła</router-link>
            <v-spacer />
            <v-btn @click="login" color="amber accent-3" class="white--text" :x-large="true"
              >Login</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mdiAccount, mdiLock } from '@mdi/js';

  export default {
    props: {
      source: String,
    },
    data: () => ({
      icons: {
        mdiAccount,
        mdiLock,
      },
      user: {
        login: null,
        pass: null,
      },
      message: null,
    }),
    methods: {
      login: function () {
        this.message = null;
        this.$store
          .dispatch('login', this.user)
          .then(() => this.$router.push('/'))
          .catch(err => (this.message = err));
      },
    },
    created() {
      if (this.$store.state.token) this.$router.push('/');
    },
  };
</script>
