<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Dokończ rejestracje</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="validPre" lazy="true">
          <v-container>
            <v-row>
              <v-alert class="col-12" v-if="message" type="error">
                {{ message }}
              </v-alert>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="editedItem.login"
                  label="Nowy login"
                  :rules="loginRule"
                  maxlength="30"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="editedItem.name"
                  label="Imię"
                  :rules="nameRule"
                  maxlength="50"
                  name="firstName"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="editedItem.surname"
                  label="Nazwisko"
                  :rules="surnameRule"
                  maxlength="50"
                  name="lastName"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="editedItem.birth_date"
                  label="Data urodzenia (opcjonalne)"
                  type="date"
                  name="birthDate"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="editedItem.phone"
                  label="Nr telefonu"
                  type="tel"
                  :rules="phoneRule"
                  v-mask="'### ### ###'"
                  name="phoneNumber"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="editedItem.postcode"
                  label="Kod pocztowy"
                  :rules="postcodeRule"
                  v-mask="'##-###'"
                  name="postcode"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="editedItem.city"
                  label="Miasto"
                  :rules="[v => !!v || 'Pole jest wymagane']"
                  name="city"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="editedItem.pass"
                  label="Nowe hasło"
                  type="password"
                  :rules="passwordRule"
                  maxlength="32"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="editedItem.repass"
                  label="Powtórz nowe hasło"
                  type="password"
                  :rules="[
                    v => !!v || 'Pole jest wymagane',
                    v => new RegExp('^' + editedItem.pass + '$').test(v) || 'Hasła różnią się!',
                  ]"
                  maxlength="32"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <v-btn-toggle v-model="toggle_one" mandatory rounded>
                  <v-btn :value="false"> Osoba prywatna </v-btn>
                  <v-btn :value="true"> Firma </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" v-if="toggle_one">
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="editedItem.NIP"
                  label="NIP"
                  v-mask="'##########'"
                  :rules="[
                    v => !!v || 'Pole jest wymagane',
                    validNIP() || 'NIP ma niepoprawny format',
                  ]"
                  name="taxId"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="editedItem.company_name"
                  label="Nazwa firmy"
                  :rules="[v => !!v || 'Pole jest wymagane']"
                  maxlength="150"
                  name="companyName"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="editedItem.company_address"
                  label="Adres"
                  :rules="[v => !!v || 'Pole jest wymagane']"
                  maxlength="150"
                  name="address"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="editedItem.company_postcode"
                  label="Kod pocztowy"
                  :rules="postcodeRule"
                  v-mask="'##-###'"
                  name="postcode"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="editedItem.company_city"
                  label="Miasto"
                  :rules="[v => !!v || 'Pole jest wymagane']"
                  maxlength="150"
                  name="city"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">Anuluj</v-btn>
        <v-btn color="blue darken-1" text @click="save">Zatwierdz</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import axios from 'axios';
  import { mask } from 'vue-the-mask';

  export default {
    name: 'FirstLogin',
    directives: {
      mask,
    },
    data: () => ({
      dialog: true,
      toggle_one: false,
      editedItem: {
        name: null,
        surname: null,
        login: '',
        pass: null,
        repass: null,
        phone: null,
        postcode: null,
        city: null,
        birth_date: null,

        isCompany: false,
        company_name: null,
        company_postcode: null,
        company_city: null,
        company_address: null,
        NIP: null,
      },
      validPre: false,
      message: null,

      nameRule: [
        v => !!v || 'Pole jest wymagane',
        v =>
          /^[a-zA-Z_-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]{1,50}$/.test(v) ||
          'Imię ma niepoprawny format (maksymalnie 50 znaków, bez cyfr)',
      ],
      surnameRule: [
        v => !!v || 'Pole jest wymagane',
        v =>
          /^[a-zA-Z_-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]{1,50}$/.test(v) ||
          'Nazwisko ma niepoprawny format (maksymalnie 50 znaków, bez cyfr)',
      ],
      postcodeRule: [
        v => !!v || 'Pole jest wymagane',
        v => /^[0-9]{2}-[0-9]{3}$/.test(v) || 'Kod pocztowy ma niewłaściwy format',
      ],
      phoneRule: [
        v => !!v || 'Pole jest wymagane',
        v =>
          /^(\+[0-9]{2}[-. ]?)?[0-9]{3}[-. ]?[0-9]{3}[-. ]?[0-9]{3}$/.test(v) ||
          'Wprowadź poprawny nr telefonu',
      ],
      emailRule: [
        v => !!v || 'Pole jest wymagane',
        v =>
          /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
            v
          ) || 'Wprowadzono niepoprawny adres email',
      ],
      loginRule: [
        v => !!v || 'Pole jest wymagane',
        v => (v.length > 7 && v.length < 31) || 'Login musi mieć minmum 8 znaków',
      ],
      passwordRule: [
        v => !!v || 'Pole jest wymagane',
        v =>
          /^(?:(?=.*\d)(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[^A-Za-z0-9])(?=.*[a-z])|(?=.*[^A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[A-Z])(?=.*[^A-Za-z0-9]))(?!.*(.)\1{2,})[A-Za-z0-9!~<>,;:_=?*+#."&§%°()\|\[\]\-\$\^\@\/]{8,32}$/.test(
            v
          ) ||
          'Musi zawierać: 8-32 znaki, 1 wielką literę, 1 małą literę, 1 numer lub znak specjalny. Nie może zawierać dwóch identycznych znaków z rzędu.',
      ],
    }),
    methods: {
      validNIP: function () {
        if (this.editedItem.NIP) {
          // NIP validation
          let nip = this.editedItem.NIP.replace(/[\ \-]/gi, '');
          let weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
          let sum = 0;
          let controlNumber = parseInt(nip.substring(9, 10));
          let weightCount = weight.length;
          for (let i = 0; i < weightCount; i++) {
            sum += parseInt(nip.substr(i, 1)) * weight[i];
          }

          if (sum % 11 !== controlNumber) {
            return false;
          } else {
            return true;
          }
        }

        return false;
      },
      close: function () {
        this.$store.dispatch('logout');
      },
      save: function () {
        if (this.toggle_one) this.editedItem.isCompany = true;
        else this.editedItem.isCompany = false;
        axios({
          url: this.$store.state.url + '/api/customer/first_login',
          data: {
            token: this.$store.state.token,
            user: this.editedItem,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status !== 200) {
              this.message = resp.data.message;
              document.getElementsByClassName('v-dialog v-dialog--active')[0].scrollTop = 0;
            } else {
              this.$store.state.firt_login = false;
              localStorage.setItem('first_login', false);
              window.location.reload();
            }
          })
          .catch();
      },
    },
    created() {
      axios({
        url: this.$store.state.url + '/api/customer/get-details',
        data: {
          token: this.$store.state.token,
        },
        method: 'POST',
      })
        .then(resp => {
          if (resp.data.status !== 200) {
            let user = resp.data.customer.user;
            this.editedItem.name = user.name;
            this.editedItem.surname = user.surname;
            this.editedItem.login = user.login;
            this.editedItem.phone = user.phone;
            this.editedItem.postcode = user.postcode;
            this.editedItem.city = user.city;

            if (user.birth_date) {
              let date = new Date(user.birth_date);
              let year = date.getFullYear();
              let month = date.getMonth() + 1;
              let dt = date.getDate();

              if (dt < 10) {
                dt = '0' + dt;
              }
              if (month < 10) {
                month = '0' + month;
              }
              this.editedItem.birth_date = year + '-' + month + '-' + dt;
            }
          }
        })
        .catch();
    },
  };
</script>

<style>
</style>
