<template>
  <v-app>
    <v-app-bar app color="black" clipped-left>
      <v-app-bar-nav-icon color="white" v-if="logged" @click="drawer = !drawer" />

      <router-link :to="{ path: '/' }">
        <v-img
          v-show="$vuetify.breakpoint.name !== 'xs'"
          alt="Interni Cargo"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="./assets/horizontal-slogan-white.svg"
          width="200"
        />
      </router-link>

      <Notifications v-if="logged"></Notifications>

      <v-spacer></v-spacer>
      <v-icon color="white" v-if="logged">{{ icons.mdiLogout }}</v-icon
      ><v-tab class="white--text" v-if="logged" @click.prevent="logout">Wyloguj się</v-tab>
    </v-app-bar>

    <v-navigation-drawer
      v-if="logged"
      v-model="drawer"
      app
      clipped
      color="grey darken-4"
      width="257"
    >
      <div class="d-flex justify-center my-1">
        <v-avatar color="red">
          <span class="white--text headline">{{ initials }}</span>
        </v-avatar>
      </div>
      <div v-if="isAdmin" class="d-flex justify-center">
        <span class="grey--text grey-lighten-4 mt-1"> Administrator </span>
      </div>
      <v-divider dark class="my-1" />
      <v-list dense class="grey darken-4 py-0 my-0">
        <v-tab :to="{ name: 'home' }">
          <v-list-item dense>
            <v-list-item-action>
              <v-icon color="grey grey-lighten-4" small>{{ icons.mdiViewDashboard }}</v-icon>
            </v-list-item-action>
            <v-list-item-content class="grey--text grey-lighten-4"> Dashboard </v-list-item-content>
          </v-list-item>
        </v-tab>

        <div v-if="isAdmin">
          <v-divider dark class="my-1" />
          <h3 class="text-center grey--text grey-lighten-4">Klienci</h3>
          <v-divider dark class="my-1" />
        </div>
        <v-tab v-if="isAdmin" :to="{ name: 'allCustomers' }">
          <v-list-item dense>
            <v-list-item-action>
              <v-icon color="grey grey-lighten-4">{{ icons.mdiAccountMultiple }}</v-icon>
            </v-list-item-action>
            <v-list-item-content class="grey--text grey-lighten-4">
              Lista klientów
            </v-list-item-content>
          </v-list-item>
        </v-tab>
        <v-tab v-if="isAdmin" :to="{ name: 'allAdmins' }">
          <v-list-item dense>
            <v-list-item-action>
              <v-icon color="grey grey-lighten-4">{{ icons.mdiAccountKey }}</v-icon>
            </v-list-item-action>
            <v-list-item-content class="grey--text grey-lighten-4">
              Administratorzy
            </v-list-item-content>
          </v-list-item>
        </v-tab>

        <v-divider dark class="my-1" />
        <h3 class="text-center grey--text grey-lighten-4">Transporty</h3>
        <v-divider dark class="my-1" />
        <v-tab :to="{ name: 'addTransport' }">
          <v-list-item dense>
            <v-list-item-action>
              <v-icon color="grey grey-lighten-4">{{ icons.mdiPlus }}</v-icon>
            </v-list-item-action>
            <v-list-item-content class="grey--text grey-lighten-4">
              Dodaj zlecenie
            </v-list-item-content>
          </v-list-item>
        </v-tab>

        <v-tab v-if="!isAdmin" :to="{ name: 'allTransport' }">
          <v-list-item dense>
            <v-list-item-action>
              <v-icon color="grey grey-lighten-4">{{ icons.mdiFormatListChecks }}</v-icon>
            </v-list-item-action>
            <v-list-item-content class="grey--text grey-lighten-4">
              Lista zleceń
            </v-list-item-content>
          </v-list-item>
        </v-tab>

        <v-tab v-if="isAdmin" :to="{ name: 'DeliveryList' }">
          <v-list-item dense>
            <v-list-item-action>
              <v-icon color="grey grey-lighten-4">{{ icons.mdiFormatListChecks }}</v-icon>
            </v-list-item-action>
            <v-list-item-content class="grey--text grey-lighten-4">
              Lista dostaw
            </v-list-item-content>
          </v-list-item>
        </v-tab>

        <v-tab v-if="isAdmin" :to="{ name: 'PickUpList' }">
          <v-list-item dense>
            <v-list-item-action>
              <v-icon color="grey grey-lighten-4">{{ icons.mdiFormatListText }}</v-icon>
            </v-list-item-action>
            <v-list-item-content class="grey--text grey-lighten-4">
              Lista odbiorów
            </v-list-item-content>
          </v-list-item>
        </v-tab>

        <v-tab v-if="isAdmin" :to="{ name: 'settingsTransport' }">
          <v-list-item dense>
            <v-list-item-action>
              <v-icon color="grey grey-lighten-4">{{ icons.mdiSettings }}</v-icon>
            </v-list-item-action>
            <v-list-item-content class="grey--text grey-lighten-4">
              Konfiguracja
            </v-list-item-content>
          </v-list-item>
        </v-tab>

        <v-divider dark class="my-1" />
        <h3 class="text-center grey--text grey-lighten-4">Finanse</h3>
        <v-divider dark class="my-1" />
        <!-- <v-tab v-if="!isAdmin" :to="{ name: 'singleCustomer' }">
          <v-list-item dense>
            <v-list-item-action>
              <v-icon color="grey grey-lighten-4">{{ icons.mdiTrendingUp }}</v-icon>
            </v-list-item-action>
            <v-list-item-content class="grey--text grey-lighten-4">
              Podsumowanie
            </v-list-item-content>
          </v-list-item>
        </v-tab> -->

        <v-tab v-if="!isAdmin" :to="{ name: 'allPayments' }">
          <v-list-item dense>
            <v-list-item-action>
              <v-icon color="grey grey-lighten-4">{{ icons.mdiFormatListChecks }}</v-icon>
            </v-list-item-action>
            <v-list-item-content class="grey--text grey-lighten-4"> Płatności </v-list-item-content>
          </v-list-item>
        </v-tab>

        <v-tab v-if="!isAdmin" :to="{ name: 'allPickupPayments' }">
          <v-list-item dense>
            <v-list-item-action>
              <v-icon color="grey grey-lighten-4">{{ icons.mdiTruckDelivery }}</v-icon>
            </v-list-item-action>
            <v-list-item-content class="grey--text grey-lighten-4"> Pobrania </v-list-item-content>
          </v-list-item>
        </v-tab>

        <!-- <v-tab v-if="!isAdmin" :to="{ name: 'allInvoices' }">
          <v-list-item dense>
            <v-list-item-action>
              <v-icon color="grey grey-lighten-4">{{ icons.mdiFileDocument }}</v-icon>
            </v-list-item-action>
            <v-list-item-content class="grey--text grey-lighten-4"> Faktury </v-list-item-content>
          </v-list-item>
        </v-tab> -->

        <v-tab v-if="!isAdmin" :to="{ name: 'priceTableCustomer' }">
          <v-list-item dense>
            <v-list-item-action>
              <v-icon color="grey grey-lighten-4">{{ icons.mdiTable }}</v-icon>
            </v-list-item-action>
            <v-list-item-content class="grey--text grey-lighten-4">
              Moje produkty
            </v-list-item-content>
          </v-list-item>
        </v-tab>

        <v-tab v-if="!isAdmin" :to="{ name: 'paymentsConfig' }">
          <v-list-item dense>
            <v-list-item-action>
              <v-icon color="grey grey-lighten-4">{{ icons.mdiSettings }}</v-icon>
            </v-list-item-action>
            <v-list-item-content class="grey--text grey-lighten-4">
              Konfiguracja
            </v-list-item-content>
          </v-list-item>
        </v-tab>

        <v-tab v-if="isAdmin" :to="{ name: 'paymentsAdd' }">
          <v-list-item dense>
            <v-list-item-action>
              <v-icon color="grey grey-lighten-4">{{ icons.mdiCashMultiple }}</v-icon>
            </v-list-item-action>
            <v-list-item-content class="grey--text grey-lighten-4">
              Dodaj płatność
            </v-list-item-content>
          </v-list-item>
        </v-tab>

        <v-tab v-if="isAdmin" :to="{ name: 'paymentsPickupAdd' }">
          <v-list-item dense>
            <v-list-item-action>
              <v-icon color="grey grey-lighten-4">{{ icons.mdiTruckDelivery }}</v-icon>
            </v-list-item-action>
            <v-list-item-content class="grey--text grey-lighten-4">
              Opłać pobranie
            </v-list-item-content>
          </v-list-item>
        </v-tab>

        <v-tab v-if="isAdmin" :to="{ name: 'paymentsList' }">
          <v-list-item dense>
            <v-list-item-action>
              <v-icon color="grey grey-lighten-4">{{ icons.mdiFormatListChecks }}</v-icon>
            </v-list-item-action>
            <v-list-item-content class="grey--text grey-lighten-4">
              Lista płatności
            </v-list-item-content>
          </v-list-item>
        </v-tab>

        <!-- <v-tab v-if="isAdmin" :to="{ name: 'paymentsAddInvoice' }">
          <v-list-item dense>
            <v-list-item-action>
              <v-icon color="grey grey-lighten-4">{{ icons.mdiFileDocument }}</v-icon>
            </v-list-item-action>
            <v-list-item-content class="grey--text grey-lighten-4">
              Dodaj fakturę
            </v-list-item-content>
          </v-list-item>
        </v-tab> -->


        <div v-if="isAdmin">
          <v-divider dark class="my-1" />
          <h3 class="text-center grey--text grey-lighten-4">Kierowcy</h3>
          <v-divider dark class="my-1" />
        </div>
        <v-tab v-if="isAdmin" :to="{ name: 'allDrivers' }">
          <v-list-item dense>
            <v-list-item-action>
              <v-icon color="grey grey-lighten-4">{{ icons.mdiAccountMultiple }}</v-icon>
            </v-list-item-action>
            <v-list-item-content class="grey--text grey-lighten-4">
              Kierowcy
            </v-list-item-content>
          </v-list-item>
        </v-tab>

        <v-divider dark class="my-1" />
        <h3 class="text-center grey--text grey-lighten-4">Platforma</h3>
        <v-divider dark class="my-1" />

        <v-tab :to="{ name: 'ticketsList' }">
          <v-list-item dense>
            <v-list-item-action>
              <v-icon color="grey grey-lighten-4">{{ icons.mdiAlertOctagon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content v-if="isAdmin" class="grey--text grey-lighten-4 overflow-auto">
              <v-badge style="flex: none" v-if="new_tickets > 0" color="red" :content="new_tickets">
                Reklamacje
              </v-badge>
              <span style="flex: none" v-else>Reklamacje</span>
            </v-list-item-content>
            <v-list-item-content v-if="!isAdmin" class="grey--text grey-lighten-4">
              Twoje zgłoszenia
            </v-list-item-content>
          </v-list-item>
        </v-tab>

        <v-tab to="/about">
          <v-list-item dense>
            <v-list-item-action>
              <v-icon color="grey grey--text grey-lighten-4">{{ icons.mdiResponsive }}</v-icon>
            </v-list-item-action>
            <v-list-item-content class="grey--text grey-lighten-3"> O nas </v-list-item-content>
          </v-list-item>
        </v-tab>

        <v-tab v-if="isAdmin" to="/administatorzy/komunikaty">
          <v-list-item dense>
            <v-list-item-action>
              <v-icon color="grey grey--text grey-lighten-4">{{ icons.mdiAccessPoint }}</v-icon>
            </v-list-item-action>
            <v-list-item-content class="grey--text grey-lighten-3">
              Komunikaty
            </v-list-item-content>
          </v-list-item>
        </v-tab>

        <v-container v-if="!isAdmin" class="ps-0">
          <v-divider dark class="my-1" />
          <h3 class="text-center grey--text grey-lighten-4">Konto</h3>
          <v-divider dark class="my-1" />

          <v-tab v-if="!isAdmin" to="/ustawienia">
            <v-list-item dense>
              <v-list-item-action>
                <v-icon color="grey grey-lighten-4">{{ icons.mdiSettings }}</v-icon>
              </v-list-item-action>
              <v-list-item-content class="grey--text grey-lighten-3">
                Ustawienia
              </v-list-item-content>
            </v-list-item>
          </v-tab>
        </v-container>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
  import store from './store';
  import axios from 'axios';

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response && 401 === error.response.status) store.dispatch('logout');

      return Promise.reject(error);
    }
  );

  import {
    mdiPlus,
    mdiLogout,
    mdiFormatListChecks,
    mdiFormatListText,
    mdiResponsive,
    mdiTruckDelivery,
    mdiViewDashboard,
    mdiTrendingUp,
    mdiCashMultiple,
    mdiAccountMultiple,
    mdiAccountKey,
    mdiSettings,
    mdiFileDocument,
    mdiAlertOctagon,
    mdiAccessPoint,
    mdiTable,
  } from '@mdi/js';
  import Notifications from './components/Notifications.vue';

  export default {
    name: 'App',
    components: {
      Notifications,
    },
    data: () => ({
      drawer: null,
      icons: {
        mdiPlus,
        mdiLogout,
        mdiResponsive,
        mdiFormatListChecks,
        mdiFormatListText,
        mdiTruckDelivery,
        mdiViewDashboard,
        mdiTrendingUp,
        mdiCashMultiple,
        mdiAccountMultiple,
        mdiAccountKey,
        mdiSettings,
        mdiFileDocument,
        mdiAlertOctagon,
        mdiAccessPoint,
        mdiTable,
      },
      new_tickets: 0,
      notifications: [],
    }),
    watch: {
      '$route.path': function (next, before) {
        if (next != before) {
          window.scrollTo(0, 0);
        }
      },
    },
    computed: {
      logged: function () {
        return store.getters.isLoggedIn ? true : false;
      },
      isAdmin: function () {
        return store.getters.isAdmin ? true : false;
      },
      initials: function () {
        return store.getters.initials ? store.getters.initials : '';
      },
    },
    methods: {
      logout: function () {
        this.$store.dispatch('logout');
      },
      get_dashboard: function () {
        axios({
          url: this.$store.state.url + '/api/admin/dashboard',
          data: {
            token: this.$store.state.token,
          },
          method: 'POST',
        }).then(resp => {
          if (resp.status === 200) {
            this.new_tickets = resp.data.new_tickets;
          }
        });
      },
    },
    mounted() {
      if (this.isAdmin) {
        this.get_dashboard();
      }
    },
  };
</script>
<style>
  body,
  .v-content__wrap {
    background-color: #f5f5f5 !important;
  }
  a {
    text-decoration: none;
  }
  .v-list-item__action {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .v-list-item__content.grey--text:hover {
    color: #fff !important;
  }
  .v-list-item.theme--light:hover svg {
    color: #fff !important;
  }
  @media screen and (max-width: 1000px) {
    .v-input .v-label {
      height: auto !important;
    }
  }
  .v-application .v-list-item__content.overflow-auto {
    overflow: visible !important;
  }
</style>
