<template>
  <div>
    <v-progress-circular v-show="loading" indeterminate size="64"></v-progress-circular>
    <v-card v-show="!loading" class="mx-auto" max-width="800" outlined>
      <v-card-title v-if="title">
        {{ message.title }}
      </v-card-title>
      <v-card-text>
        <v-row v-if="errorMsg">
          <v-col cols="12" sm="12">
            <v-alert type="error" class="ma-0">
              {{ errorMsg }}
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              v-model="message.title"
              name="title"
              label="Tytuł wiadomości"
              hint="Wprowadź tytuł wiadomości"
              v-if="!title"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-textarea
              outlined
              v-model="message.content"
              name="content"
              label="Twoja wiadomość"
              :hint="hintMsg"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-spacer />
          <v-col cols="12" md="5">
            <v-btn
              @click="sendMessage"
              :disabled="preventBtn || !message.title || message.content.length < 1"
              color="amber accent-3"
              class="grey--text text--darken-4"
              :x-large="true"
              v-tilt
              >Wyślij wiadomość</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    props: {
      title: String,
      send_to: Number,
      transport_id: Number,
    },
    name: 'MessageDialog',
    data: () => ({
      loading: true,
      message: {
        title: '',
        content: '',
      },
      hintMsg: 'Twoja wiadomość trafi do ',
      errorMsg: '',
      preventBtn: false,
    }),
    methods: {
      sendMessage: function () {
        this.preventBtn = true;
        this.errorMsg = null;

        axios({
          url: this.$store.state.url + '/api/messages/create',
          data: {
            token: this.$store.state.token,
            message: this.message,
            user_id: this.send_to,
            transport_id: this.transport_id,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status === 200) {
              window.location.reload();
            } else if (resp.data.status === 403) {
              this.errorMsg = resp.data.message;

              let that = this;
              setTimeout(() => {
                that.preventBtn = false;
              }, 200);
            } else {
              this.errorMsg = resp.data.message;

              let that = this;
              setTimeout(() => {
                that.preventBtn = false;
              }, 200);
            }
          })
          .catch(() => {
            let that = this;
            setTimeout(() => {
              that.preventBtn = false;
            }, 200);
          });
      },
    },
    created() {
      if (this.title) {
        this.message.title = this.title.slice();
      }
      if (this.send_to) {
        this.hintMsg += 'użytkownika';
      } else {
        this.hintMsg += 'administracji';
      }
      setTimeout(() => {
        this.loading = false;
      }, 200);
    },
  };
</script>
